

<ng-container *ngIf="employee">
  <div class="picture-cotainer" (mouseenter)="onMouseenter()" (mouseleave)="onMouseleave()">
    <div class="picture"
      [ngStyle]="{'background': ('url(' + imgPath + ')')
      }"> </div>


  </div>

  <div class="profileName">{{ employee.fullNameShort}}</div>
  <div class="profileProfession">{{ employee.position}}</div>

</ng-container>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { TeamProfileInfo } from 'src/app/shared/models/common.model';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OurTeamComponent implements OnInit {

  displayedEmployees: TeamProfileInfo[] = [];

  employees: TeamProfileInfo[] = [];
  private itemsPerPage = 20;

  constructor(private employeesService: EmployeesService) {
    this.employees = this.employeesService.getOurTeams();
    this.displayedEmployees = this.employees.splice(0, this.itemsPerPage);
   }

  ngOnInit() {
  }

  nextPage() {
    this.displayedEmployees = [...this.displayedEmployees, ...this.employees.splice(0, this.itemsPerPage)];
  }
}

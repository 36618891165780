import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TeamProfileInfo } from 'src/app/shared/models/common.model';
import {ImageCustomService} from 'src/app/services/image-custom.service'

@Component({
  selector: '[app-our-team-item]',
  templateUrl: './our-team-item.component.html',
  styleUrls: ['./our-team-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OurTeamItemComponent implements OnInit {

  @Input() employee: TeamProfileInfo;

  imgPath: string;

  constructor(private imageCustomService: ImageCustomService) { }

  ngOnInit() {
    this.imgPath = this.employee.imgPath;
    this.imageCustomService.preloadImage(this.employee?.hoverImgPath);
  }

  onMouseenter() {
    this.imgPath = this.employee.hoverImgPath;
  }

  onMouseleave() {
    this.imgPath = this.employee.imgPath;
  }
}

<div class="ourleaders" id="page">

  <div class="title">
    {{"OUR_LEADERS.Our_Team"|translate}}
  </div>

  <div class="leaders" *ngIf="displayedEmployees">

    <div class="person" app-our-team-item  *ngFor="let item of displayedEmployees" [employee]="item">
    </div>

  </div>

  <div class="show-more-container" (click)="nextPage()" *ngIf="employees?.length">
    {{"COMMON.Show_more"|translate}}
  </div>

</div>
